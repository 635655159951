import { React, useContext, createContext, useState, useEffect } from "react";

import en from "@accrosoft-ltd/vf-careers-site-theme/src/locales/en-GB/translation.json";
import cy from "@accrosoft-ltd/vf-careers-site-theme/src/locales/cy-GB/translation.json";

const INITIAL_STATE = {
  lang:
    typeof window !== "undefined" && localStorage
      ? localStorage.getItem("lang") || "en-GB"
      : "en-GB",
};

const I18nContext = createContext();

export const useI18nContext = () => {
  return useContext(I18nContext);
};

const I18nextProvider = ({ children }) => {
  const [i18nState, setI18nState] = useState(INITIAL_STATE);

  typeof window !== "undefined" &&
    localStorage &&
    localStorage.setItem("lang", i18nState.lang);

  const getTranslation = (key, data) => {
    var keys = key.split(".");

    keys.forEach((child) => {
      if (!data[child]) {
        data = "";
        return;
      } else {
        data = data[child];
      }
    });

    return data;
  };

  const t = (key, options = { vars: {}, defaultValue: key }) => {
    var translation;

    if (i18nState.lang === "cy-GB") {
      translation = getTranslation(key, cy);
    }

    if (!translation) translation = getTranslation(key, en);

    if (options.vars && translation) {
      Object.keys(options.vars).forEach((key) => {
        translation = translation.replace(`$${key}`, options.vars[key]);
      });
    }
    return translation || options.defaultValue;
  };

  const changeLang = (code) => {
    setI18nState({ ...i18nState, lang: code });
    localStorage.setItem("lang", code);
  };

  return (
    <I18nContext.Provider
      value={{
        i18nState,
        setI18nState,
        t,
        changeLang,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nextProvider;
